import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import './PopupDownloadMessage.scss'

interface Props {
  mobileNumber?: string
  game?: string
  // popClose(): void
}
const SITE_URL = `https://www.getmega.com`
const PopupDownloadMessage: React.FC<Props> = ({ mobileNumber, game }) => {
  const data = useStaticQuery(graphql`
    {
      iconCheck: file(relativePath: { eq: "check_icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 64, layout: FIXED)
        }
      }
      imageTab: file(relativePath: { eq: "tab_bg.png" }) {
        childImageSharp {
          gatsbyImageData(width: 320, layout: FIXED)
        }
      }
    }
  `)

  const downloadLink =
    game?.toLowerCase() === 'rummy' ? 'latest-rc' : 'latest-social'

  return (
    <>
      <div className="popupMessage">
        <div className="popupMessageContent">
          <div className="check-icon">
            <GatsbyImage
              image={data.iconCheck.childImageSharp.gatsbyImageData}
              alt=""
            />
          </div>
          <div className="popupMessageTitle">
            Successfully sent an sms to +91 {mobileNumber}
          </div>
          <div className="popupMessageText">
            <GatsbyImage
              image={data.imageTab.childImageSharp.gatsbyImageData}
              alt=""
            />
            <div className="popupMessageBluebox">
              <span>
                Play real money games with real players. Download the Mega app
                now.
              </span>
              <a href={`${SITE_URL}/${downloadLink}`}>
                https://getmega.com/{downloadLink}
              </a>
            </div>
            <div className="smallText">
              Click the download link you received in sms
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default PopupDownloadMessage
