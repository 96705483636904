import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Portal } from 'react-portal'
import './PopupDownload.scss'
import SendSms from '../Index/MainScreen/Desktop/SendSms'
import PopupDownloadMessage from './PopupDownloadMessage'

interface Props {
  close(): void
  gameName?: string
  apkLink?: string
  showPokerOnly?: boolean
  showRummyOnly?: boolean
  pokerLink?: Record<'android' | 'ios' | 'web', string>
  rummyLink?: Record<'android' | 'ios' | 'web', string>
  lang?: string
}

const PopupDownload: React.FC<Props> = ({
  close,
  gameName,
  apkLink,
  showPokerOnly,
  showRummyOnly,
  pokerLink,
  rummyLink,
  lang,
}) => {
  const [smsResponse, setSmsResponse] = useState(false)
  const [mobNumber, setMobNumber] = useState('')

  const data = useStaticQuery(graphql`
    {
      imageClose: file(relativePath: { eq: "close-btn-popup-download.png" }) {
        childImageSharp {
          gatsbyImageData(width: 24, layout: CONSTRAINED)
        }
      }
      imageLogo: file(relativePath: { eq: "getmega-logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 40, layout: FIXED)
        }
      }
      imageStar: file(relativePath: { eq: "star-48.png" }) {
        childImageSharp {
          gatsbyImageData(width: 12, layout: FIXED)
        }
      }
    }
  `)

  let gamesName = lang === 'hindi' ? 'पोकर और रमी गेम्स' : 'Poker & Rummy Games'

  let smsForm = (
    <>
      <div className="popup-sms-wrap">
        <div className="game-info">
          <StaticImage
            src="../../images/thumbnails/Verticle_TexasHoldem.png"
            alt="poker"
            width={40}
          />
          <span>{lang === 'hindi' ? 'पोकर' : 'Poker'}</span>
        </div>
        <SendSms
          smsLink={pokerLink}
          game="poker"
          downloadBtnText={
            lang === 'hindi' ? 'पोकर डाउनलोड करें' : 'Download Poker'
          }
          lang={lang}
        />
      </div>
      <div className="popup-sms-wrap">
        <div className="game-info">
          <StaticImage
            src="../../images/thumbnails/Rummy-Thumbnails.png"
            alt="rummy"
            width={40}
          />
          <span>{lang === 'hindi' ? 'रमी' : 'Rummy'}</span>
        </div>
        <SendSms
          smsLink={rummyLink}
          game="rummy"
          downloadBtnText={
            lang === 'hindi' ? 'रमी डाउनलोड करें' : 'Download Rummy'
          }
          lang={lang}
        />
      </div>
    </>
  )

  if (showPokerOnly && !showRummyOnly) {
    smsForm = (
      <div className="popup-sms-wrap">
        <div className="game-info">
          <StaticImage
            src="../../images/thumbnails/Verticle_TexasHoldem.png"
            alt="poker"
            width={40}
          />
          <span>{lang === 'hindi' ? 'पोकर' : 'Poker'}</span>
        </div>
        <SendSms smsLink={pokerLink} game="poker" lang={lang} />
      </div>
    )
    gamesName = 'Poker game'
  }

  if (!showPokerOnly && showRummyOnly) {
    smsForm = (
      <div className="popup-sms-wrap">
        <div className="game-info">
          <StaticImage
            src="../../images/thumbnails/Rummy-Thumbnails.png"
            alt="rummy"
            width={40}
          />
          <span>{lang === 'hindi' ? 'रमी' : 'Rummy'}</span>
        </div>
        <SendSms smsLink={rummyLink} game="rummy" lang={lang} />
      </div>
    )
    gamesName = lang === 'hindi' ? 'रमी खेल' : 'Rummy game'
  }

  const textDesc = <div className="textDesc">{gamesName}</div>

  const popupDownloadContent = (
    <>
      <div className="d-block d-sm-none">
        <div className="pdcText">
          {lang === 'hindi' ? (
            <>क्या आप गेम नहीं खेलना चाहते हैं और असली नकद जीतना चाहते हैं?</>
          ) : (
            <>Don&apos;t you want to play games &amp; win real cash?</>
          )}
        </div>
      </div>
      <div className="d-none d-sm-block">
        <div className="pdcText">
          {lang === 'hindi' ? (
            <>
              वेब पर गेम खेलना प्रारंभ करें <br />
              और असली नकद जीतें
            </>
          ) : (
            <>
              Start playing games on web <br />
              and win real cash
            </>
          )}
        </div>
      </div>
      {smsForm}
    </>
  )

  return (
    <Portal>
      <div className="popupDownloadBackdrop">
        <div className="popupDownload">
          <div className="popupDownloadClose">
            <button title="close" type="button" onClick={close}>
              <GatsbyImage
                image={data.imageClose.childImageSharp.gatsbyImageData}
                alt="close button"
                className="close-icon"
              />
            </button>
          </div>
          {smsResponse ? (
            <>
              <PopupDownloadMessage mobileNumber={mobNumber} game={gameName} />
              <div className="popupMessageFooter">
                <div className="popupMessageResend">Didn&apos;t receive?</div>
                <div className="popupMessageGotBtn">
                  <button type="button" onClick={close}>
                    GOT IT
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="popupDownloadWrap">
                <div className="popupDownloadHeader">
                  <div className="popupDownloadTitle">
                    <div className="img">
                      <GatsbyImage
                        image={data.imageLogo.childImageSharp.gatsbyImageData}
                        alt=""
                      />
                    </div>
                    <div className="popupTitleText">
                      <div className="textHeading">
                        {lang === 'hindi' ? 'गेटमेगा' : 'Mega'}
                      </div>
                      {textDesc}
                    </div>
                  </div>
                </div>
                <div className="popupDownloadContent">
                  {popupDownloadContent}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Portal>
  )
}

export default PopupDownload
